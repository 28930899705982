import React from "react";
import styles from "assets/css/app.module.scss"
import {createTheme, Theme, ThemeProvider} from "@mui/material/styles";
import {darkScrollbar, Grow} from "@mui/material";
import {useAppSelector} from "./utils/redux/hooks";
import Footer from "./views/Footer";
import TopBar from "./views/topbar/TopBar";
import Main from "./views/Main"
import {useLocation} from "react-router-dom";
import { SnackbarProvider } from "notistack"
import {TransitionProps} from "@mui/material/transitions";

function App() {
    // The `state` arg is correctly typed as `RootState` already
    const mode = useAppSelector(state => state.theme.mode)

    const theme: Theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode:  mode === "dark" ? "dark" : "light",
                },
                components: {
                    MuiCssBaseline: {
                        styleOverrides: {
                            body: mode === "dark" ? darkScrollbar() : null,
                        },
                    },
                }
            }),
        [mode],
    );
    const location = useLocation();
    const longContentRoutePath = ['/notes/notePage/']

    const contentStyle = () => {
        const index = longContentRoutePath.findIndex(path => {
            return location.pathname.indexOf(path) >= 0
        })
        if (index !== -1) {
            return styles.longContent
        } else {
            return styles.content
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={5}
                TransitionComponent={Grow as React.ComponentType<TransitionProps>}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <div className={mode === "light" ? styles.background_light : styles.background_dark}>
                    <div className={styles.header}>
                        <TopBar/>
                    </div>
                    <div className={styles.main}>
                        <div className={contentStyle()}>
                            <Main />
                        </div>
                        <div className={styles.footer}>
                            <Footer />
                        </div>
                    </div>
                </div>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
