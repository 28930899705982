import axios, {AxiosRequestConfig, AxiosResponse, Method} from "axios"
import {handleDates} from "./date-interceptor";
/**
 * server config according to different kind of deployment
 */
const server_config = {
    production: {
        baseURL: "https://api.inch.icu/",
        timeout: 1000,
        withCredentials: false,
    } as AxiosRequestConfig,
    development: {
        baseURL: "http://localhost:21292/",
        timeout: 1000,
        withCredentials: false,
    } as AxiosRequestConfig,
    debug: {
        baseURL: "http://localhost:21292/",
        timeout: 1000,
        withCredentials: false,
    } as AxiosRequestConfig
}

/**
 * get config base on different mode: production/development/debug
 * default is development mode
 *
 * declare process type first
 */
declare var process: {
    env: {
        NODE_ENV: string
    }
}
const config =
    process.env.NODE_ENV === "production" ? server_config.production :
        process.env.NODE_ENV === "debug" ? server_config.debug : server_config.development

/**
 * define interface of current pending request
 */
interface PendingType {
    url?: string
    method?: Method
    params: any
    data: any
    cancel: Function
}

const pending: Array<PendingType> = [];
/**
 * CancelToken check more info on:
 * https://github.com/axios/axios#cancellation
 */
const CancelToken = axios.CancelToken

/**
 * remove the same request which is pending
 * @param config
 */
const removePending = (config: AxiosRequestConfig) => {
    for (const key in pending) {
        const item: PendingType = pending[key]
        //check if the request is current requesting
        if (
            item.url === config.url &&
            item.method === config.method &&
            JSON.stringify(item.params) === JSON.stringify(config.params) &&
            JSON.stringify(item.data) === JSON.stringify(config.data)
        ) {
            //cancel the request
            item.cancel('To many request in short period')
            //delete the pending request
            pending.splice(parseInt(key),1)
        }
    }
}

/**
 * define the retry times and delay
 */
const [RETRY_COUNT, RETRY_DELAY] = [3,1000]

/**
 * create instance
 */
const instance = axios.create(config);

/**
 * add request interceptor and put access token in the header
 */
instance.interceptors.request.use(
    (requestConfig: AxiosRequestConfig) => {
        removePending(requestConfig)
        //add cancel token to list
        requestConfig.cancelToken = new CancelToken(
            (c) => {
                pending.push(
                    {
                        url: requestConfig.url,
                        method: requestConfig.method,
                        params: requestConfig.params,
                        data: requestConfig.data,
                        cancel: c
                    }
                )
            }
        )

        // add token to header
        let token = localStorage.getItem("token");
        if (!token) {
            token = ""
        }
        requestConfig.headers["token"] = token
        //output request config, for test use
        // console.log(requestConfig)
        return requestConfig
    },
    (error) => {
        console.log(error)
    }
)

/**
 * add response interceptor to handle error
 * check more on how to handle error: https://github.com/axios/axios#handling-errors
 */
instance.interceptors.response.use(
    (response: AxiosResponse) => {
        handleDates(response.data)
        //request finished successful and remove request from pending list
        removePending(response.config)
        return response
    },
    (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.status)
            console.log(error.response.data)
            console.log(error.response.headers)
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
        }

        //get request config
        const config = error.config
        // console.log(config)

        if (config && RETRY_COUNT) {
            //set up a param to store retried time
            config.retryTimes = config.retryTimes || 0;
            //used up retry times then return error
            if (config.retryTimes >= RETRY_COUNT) {
                return Promise.reject(error)
            }
            config.retryTimes++;

            //wait for a while and run again
            const timer = new Promise((resolve) => {
                setTimeout(() => {
                    console.log("Wait a second!");
                    resolve("")
                }, RETRY_DELAY || 1000)
            })
            return timer.then(() => {
                return instance(config)
            })
        }
        //can't handle the error just return it
        return Promise.reject(error)
    }
)

export default instance
