import React from 'react';
import {IconButton} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../utils/redux/hooks";
import {setMode} from "../../../utils/redux/slice/themeSlice";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";

function ThemeModeButton() {
    const mode = useAppSelector(state => state.theme.mode)
    const dispatch = useAppDispatch()

    function switchMode() {
        if (mode === "dark") {
            dispatch(setMode("light"))
        } else {
            dispatch(setMode("dark"))
        }
    }
    return (
    <IconButton size="large" onClick={() => switchMode()} color="inherit">
        {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
    );
}

export default ThemeModeButton;
