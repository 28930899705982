import React, {ReactComponentElement, useEffect, useState} from "react";
import {getNotes} from "../../api/notes";
import {useSnackbar} from "notistack";
import {
    Button, Card, CardActions, CardContent, Typography,
    // Chip,
    Pagination, Grid
} from "@mui/material";
import {Link} from "react-router-dom";
import dateFormat from "../../utils/date-utils";
function IndexPage() {
    const { enqueueSnackbar } = useSnackbar();
    const [notes, setNotes] = useState<Array<NoteEntity>>([]);
    const [pageCount, setPageCount] = useState<number>(1)
    useEffect(() => {
        getNotes()
            .then(data => {
                setNotes(data.listObject)
                setPageCount(data.pageCount)
            })
            .catch(err => {
                enqueueSnackbar("获取随笔失败: " + err.status + " " + err.message, {variant : "error"})
            })
        // eslint-disable-next-line
    },[])

    const onPageChange = (event : React.ChangeEvent<unknown>, page: number) => {
        getNotes(page)
            .then(data => {
                setNotes(data.listObject)
                setPageCount(data.pageCount)
            })
            .catch(err => {
                enqueueSnackbar("获取随笔失败: " + err.status + " " + err.message, {variant : "error"})
            })
    }

    const createNoteCards = () : Array<ReactComponentElement<any>>  => {
        const noteCards : Array<ReactComponentElement<any>> = []
        notes.forEach(note => {
            noteCards.push(
                <Grid item xs={12} key={note.id}>
                    <Card sx={{ minWidth: 200, width: '100%' }}>
                        <CardContent>
                            {/* todo 预留分类显示位置*/}
                            {/*<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>*/}
                            {/*    {{note.type}}*/}
                            {/*</Typography>*/}
                            <Typography variant="h5" component="div">
                                { note.title }
                            </Typography>
                            <Typography variant="caption" sx={{ mb: 1.5 }} color="text.secondary">
                                { dateFormat(note.createTime, 'yyyy-MM-dd hh:mm:ss') }
                            </Typography>
                            {/* todo 预留标签显示位置*/}
                            {/*<Typography sx={{ mb: 1.5 }} color="text.secondary">*/}
                            {/*    { note.tags.map(tag => <Chip label={tag} />) }*/}
                            {/*</Typography>*/}
                            <Typography variant="body2">
                                { note.intro }
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Link to={ "/notes/notePage/" + note.id} style={{textDecoration: "none"}}>
                                <Button size="small">查看</Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid >)
        })
        return noteCards;
    }

    return (
        <Grid container spacing={2}>
            {createNoteCards()}
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Pagination count={pageCount} onChange={onPageChange}/>
            </Grid>
        </Grid>
    );
}

export default IndexPage;
