import instance from "./axios"
import {AxiosRequestConfig} from "axios";

/**
 * Post function
 * @param url the url after baseurl
 * @param body the data body
 * @returns {Promise<HttpResult>}
 * @constructor
 */
export const POST = (url: string, body: any) => {
    return new Promise(
        (resolve: Function, reject: Function) => {
            instance
                .post(url, JSON.stringify(body))
                .then(res => {
                    const result: HttpResult = {status: res.status, message: "success", data: res.data}
                    resolve(result)
                })
                .catch(err => {
                    if (err.response){
                        const result: HttpResult = {status: err.response.data.status, message: err.response.data.message, data: err.response.data}
                        reject(result)
                    } else {
                        const result: HttpResult = {status: 404, message: 'Not Found', data: err}
                        reject(result)
                    }
                })
        }
    )
}

/**
 * Get function
 *
 * @param url url after baseurl
 * @param params the params
 * @returns {Promise<HttpResult>}
 * @constructor
 */
export function GET(url: string, params?: object) {
    return new Promise((resolve: (res: HttpResult) => void, reject: (res: HttpResult) => void) => {
        instance.get(url, {params: params})
            .then(res => {
                const result: HttpResult = {status: res.status, message: "success", data: res.data}
                resolve(result)
            })
            .catch(err => {
                if (err.response){
                    const result: HttpResult = {status: err.response.data.status, message: err.response.data.message, data: err.response.data}
                    reject(result)
                } else {
                    const result: HttpResult = {status: 404, message: 'Not Found', data: err}
                    reject(result)
                }
            })
    })
}

/**
 * upload file
 *
 * @param url request url
 * @param file the file to upload
 * @param filename filename
 * @param progressFunction the progress function receive a ProgressEvent as a param
 * @returns {Promise<HttpResult>}
 * @constructor
 */
export function UPLOAD(url: string, file: File, filename: string, progressFunction: Function) {
    const form = new FormData()
    form.append("filename", filename)
    form.append("file", file)
    const config: AxiosRequestConfig = { headers: {"Content-Type": "multipart/form-data"}}
    // if user defined a progress function then give a ProgressEvent object as param to this function
    if (progressFunction) {
        config.onUploadProgress = (e: ProgressEvent) => {
            progressFunction(e)
        }
    }
    return new Promise((resolve,reject) => {
        instance.post(url, form, {headers: {"Content-Type": "multipart/form-data"}})
            .then(res => {
                const result: HttpResult = {status: res.status, message: "success", data: res.data}
                resolve(result)
            })
            .catch(err => {
                if (err.response){
                    const result: HttpResult = {status: err.response.data.status, message: err.response.data.message, data: err.response.data}
                    reject(result)
                } else {
                    const result: HttpResult = {status: 404, message: 'Not Found', data: err}
                    reject(result)
                }
            })
    })
}

/**
 * Download file
 *
 * @param url request url
 * @param params request params
 * @param progressFunction the progress function receive a ProgressEvent as a param
 * @returns {Promise<HttpResult>}
 * @constructor
 */
export function DOWNLOAD(url: string, params: Array<any>,progressFunction: Function) {
    const config:AxiosRequestConfig = {params: params}
    // if user defined a progress function then give a ProgressEvent object as param to this function
    if (progressFunction) {
        config.onDownloadProgress = (e: ProgressEvent) => {
            progressFunction(e)
        }
    }
    return new Promise((resolve,reject) => {
        instance.get(url, config)
            .then(res => {
                const result: HttpResult = {status: res.status, message: "success", data: res.data}
                resolve(result)
            })
            .catch(err => {
                if (err.response){
                    const result: HttpResult = {status: err.response.data.status, message: err.response.data.message, data: err.response.data}
                    reject(result)
                } else {
                    const result: HttpResult = {status: 404, message: 'Not Found', data: err}
                    reject(result)
                }
            })
    })
}
