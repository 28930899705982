import React from "react";
import {ListItemIcon, ListItemText, MenuItem, MenuList, Paper} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import ArchiveIcon from "@mui/icons-material/Archive";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import {useSnackbar} from "notistack";
import { useNavigate } from "react-router-dom"


function NotesMenu() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const menuClicked = (menu? : string) => {
        if (menu) {
            switch (menu) {
                case "index":
                    navigate("/notes")
                break;
            }
        } else {
            enqueueSnackbar("未开放", {variant: "warning"})
        }
    }

    return (
        <Paper sx={{width: "100%"}}>
            <MenuList>
                <MenuItem onClick={() => menuClicked('index')}>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText>首页</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => menuClicked()}>
                    <ListItemIcon>
                        <CategoryIcon />
                    </ListItemIcon>
                    <ListItemText>分类</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => menuClicked()}>
                    <ListItemIcon>
                        <ArchiveIcon />
                    </ListItemIcon>
                    <ListItemText>归档</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => menuClicked()}>
                    <ListItemIcon>
                        <LocalOfferIcon />
                    </ListItemIcon>
                    <ListItemText>标签</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => menuClicked()}>
                    <ListItemIcon>
                        <ManageSearchIcon />
                    </ListItemIcon>
                    <ListItemText>搜索</ListItemText>
                </MenuItem>
            </MenuList>
        </Paper>
    );
}

export default NotesMenu;
