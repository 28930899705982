import React from "react";
import {SvgIcon} from "@mui/material";
import {ReactComponent as Wechat} from "assets/icon/svg/wechat.svg";

function WechatIcon( props:object ) {
    return (
        <SvgIcon {...props}>
            <Wechat/>
        </SvgIcon>
    );
}

export default WechatIcon;
