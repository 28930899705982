import {GET} from "../utils/http/http";
export function getMarkdown(url : string) {

    return new Promise((resolve,reject) => {
        GET(url)
            .then(res => {
                if (res.status === 200) {
                    resolve(res.data)
                } else {
                    reject("Http response code: " + (res as HttpResult).status)
                }
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function getNotes(pageNum? : number, pageSize? : number) {

    return new Promise((resolve: (data: PageData) => void, reject) => {
        GET("notes/getList", { pageNum: pageNum, pageSize: pageSize})
            .then(res => {
                resolve(res.data as PageData)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function getNote(id: number) {
    return new Promise((resolve: (data: NoteEntity) => void, reject) => {
        GET("notes/getNote", { id: id})
            .then(res => {
                resolve(res.data as NoteEntity)
            })
            .catch(err => {
                reject(err)
            })
    })
}
