import React from "react";
import styles from 'assets/css/footer.module.scss'
import {Typography} from "@mui/material";

function Footer() {
    return (
        <div className={styles.footer}>
            <Typography >
                备案号：<a href="http://beian.miit.gov.cn/">蜀ICP备2021021517号</a>
            </Typography>
        </div>
    );
}

export default Footer;
