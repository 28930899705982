import React from "react";
import {Button, Card, CardActions, CardContent, CardMedia, Container, Typography, Grid} from "@mui/material";
import GenshinImg from "../../assets/image/genshin/card-img.jpeg";
import NotesImg from "../../assets/image/notes/card-img.jpg"
import {Link} from "react-router-dom";

function CardNavigation() {
    return (
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            component="img"
                            height='200'
                            image={NotesImg}
                            alt="notes"
                        >
                        </CardMedia>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                随笔
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                学习笔记，教学，游戏，吐槽，啥都可能有
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Link to="/notes" style={{textDecoration: "none"}}>
                                <Button size="small">查看</Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            component="img"
                            height='200'
                            image={GenshinImg}
                            alt="genshin"
                        >
                        </CardMedia>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                原神模型
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                官方的模型展示，跪求有大佬做mmd
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Link to="/genshin3d" style={{textDecoration: "none"}}>
                                <Button size="small">查看</Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

export default CardNavigation;
