import React, { useEffect, useState } from "react";
import {Container} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism"
import {useMatch} from "react-router-dom"
import {useSnackbar} from "notistack";
import 'github-markdown-css'

import {getMarkdown, getNote} from "../../api/notes";
import { replaceImageUrlWithBaseUrl} from "../../utils/markdown/markdown";
import {HeadingProps} from "react-markdown/lib/ast-to-react";

function NotePage() {
    const [markdown, setMarkdown] = useState<string>("## 加载中");
    const [baseUrl, setBaseUrl] = useState<string>("")

    const match = useMatch({path: "/notes/notePage/:id", end: true, caseSensitive: true})
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        console.log(match?.params.id)
        if (match?.params.id) {
            getNote(parseInt(match.params.id))
                .then(data => {
                    setBaseUrl(data.baseUrl + "/")
                    getMarkdown(data.url)
                        .then(data => {
                            setMarkdown(data as string)
                        })
                        .catch(err => {
                            console.log(err)
                            enqueueSnackbar("获取笔记失败：" + err.message, {variant: "error"})
                        })
                })
                .catch(err => {
                    enqueueSnackbar("获取笔记信息失败：" + err.message, {variant: "error"})
                })
        } else {
            enqueueSnackbar("未找到笔记", {variant: "error"})
        }
    // eslint-disable-next-line
    },[])

    const hRender = (prop:  React.PropsWithChildren<HeadingProps>) => {
        //todo get header and add to toc
        return React.createElement(`h${prop.level}`, {id: prop.children[0] as string}, prop.children)
    }
    return (
        <Container maxWidth="md" className="markdown-body">
            <ReactMarkdown
                children={markdown}
                components={{
                    h1: hRender,
                    h2: hRender,
                    h3: hRender,
                    h4: hRender,
                    h5: hRender,
                    h6: hRender,
                    img: ({ alt, src, title}: { alt?: string; src?: string; title?: string;}) => {
                        return(
                            <img alt={alt}
                                 src={replaceImageUrlWithBaseUrl(src, baseUrl)}
                                 title={title}
                                 style={{width: "auto", height: "auto", maxWidth: "100%", maxHeight: "100%"}} />
                        )
                    },
                    code({node, inline, className, children, ...props}) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                            <SyntaxHighlighter
                                children={String(children).replace(/\n$/, '')}
                                style={darcula}
                                language={match[1]}
                                wrapLines={true}
                                PreTag="div"
                                {...props}
                            />
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        )
                    }
                }}
                remarkPlugins={[remarkGfm]}/>
        </Container>
    );
}

export default NotePage;
