const IMAGE_REGEX : RegExp  = new RegExp(/!\[(.*?)\]\((.*?)\)/mg)

export function replaceContentWithBaseUrl(content : string, baseUrl : string) : string {
    let result : string = ""
    let lastStartIndex : number = 0
    let matcher : RegExpExecArray | null = null
    while ((matcher  = IMAGE_REGEX.exec(content)) != null) {
        if(matcher[2].startsWith('./')) {
            result = result + content.substring(lastStartIndex,matcher.index)
            let replacement = baseUrl + matcher[2].substring(2)
            result = result + '![' + matcher[1] + '](' + replacement + ')'
            lastStartIndex = matcher.index + matcher[0].length
        }
        if (matcher[2].indexOf('/') === -1) {
            result = result + content.substring(lastStartIndex,matcher.index)
            let replacement = baseUrl + matcher[2]
            result = result + '![' + matcher[1] + '](' + replacement + ')'
            lastStartIndex = matcher.index + matcher[0].length
        }
    }
    result = result + content.substring(lastStartIndex, content.length)
    return result
}

export function replaceImageUrlWithBaseUrl(url : string | undefined, baseUrl : string) : string | undefined {
    let result : string | undefined = undefined
    if (url) {
        if(url.startsWith('./')) {
            result = baseUrl + url.substring(2)
        }
        if (url.indexOf('/') === -1) {
            result = baseUrl + url
        }
    }
    console.log(result)
    return result
}
