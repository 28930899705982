import React, {useState} from "react";
import {IconButton, Popover} from "@mui/material";
import WechatIcon from "../../../assets/icon/WechatIcon";
import QRCode from "assets/image/wechat-qrcode.jpg";

function WechatButton() {

    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        console.log("close")
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const id = open ? "qrcode" : undefined

    return (
        <div>
            <IconButton aria-describedby={id} size="large" onClick={handleClick} color="inherit">
                <WechatIcon/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <img style={{width: "430px", height: "430px"}} alt="QRCode" src={QRCode}/>
            </Popover>
        </div>
    );
}

export default WechatButton;
