import React, { useEffect } from "react";
import {Container, Grid } from "@mui/material";
import NotesMenu from "./components/NotesMenu";
import {Route, Routes} from "react-router-dom";
import IndexPage from "./IndexPage";
import NotePage from "./NotePage";

function Notes() {

    useEffect(() => {

    },[])

    return (
        <Container maxWidth="md">
            <Grid container spacing={2}>
                <Grid item md={2}>
                    <Grid item md={12}>
                        <NotesMenu />
                    </Grid>
                </Grid>
                <Grid item md={10} >
                    <Routes>
                        <Route path="/" element={<IndexPage />} />
                        <Route path="/notePage/:id" element={<NotePage />} />
                    </Routes>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Notes;
