import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";


// Define a type for the slice state
interface ThemeState {
    mode: String
}

// Define the initial state using that type
const initialState: ThemeState = {
    mode: 'light',
}

export const counterSlice = createSlice({
    name: 'theme',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setMode: (state, action: PayloadAction<string>) => {
            state.mode = action.payload
        }
    }
})

export const { setMode } = counterSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectTheme = (state: RootState) => state.theme

export default counterSlice.reducer
