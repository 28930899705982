import React from "react";
import styles from "assets/css/top-bar.module.scss"
import logo from "assets/image/logo.jpg"
import ThemeModeButton from "./components/ThemeModeButton";
import {IconButton} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import {Link} from "react-router-dom";
import WechatButton from "./components/WechatButton";
function TopBar() {
    return (
        <div className={styles.top_bar} >
            <Link to="/" style={{width:"100%", height: "100%",textDecoration: "none"}}>
                <div className={styles.left}>
                    <img alt="logo" src={logo} className={styles.logo}/>
                    <div className={styles.logo_text}>印池</div>
                </div>
            </Link>
            <div className={styles.right}>
                <WechatButton />
                <IconButton size="large" onClick={() => {window.open('https://github.com/autumnwindbleak')}} color="inherit">
                    <GitHubIcon/>
                </IconButton>
                <ThemeModeButton/>
            </div>
        </div>
    );
}

export default TopBar;
