import React, {useEffect, useState} from "react";
import * as THREE from "three";
import {Camera, Mesh, Renderer, Scene, SkinnedMesh} from "three";
import {MMDLoader} from "three/examples/jsm/loaders/MMDLoader";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

function MMD(props: { modelPath: string }) {
    const [mesh, setMesh] = useState<Mesh | null>();
    const [scene,] = useState<Scene>(createScene());
    const [camera, setCamera] = useState<Camera>(createCamera(1.0));
    const [renderer,] = useState<Renderer>(createRenderer());


    /**
     * change model
     * @param newModelPath new path to the model file
     */
    function replaceModel(newModelPath: string) {
        if (scene != null && mesh != null) {
            scene.remove(mesh)
        }
        const loader = new MMDLoader()
        //set camera position
        camera.position.set(0, 20, 30);
        loader.load(newModelPath, onload, onprogress, onerror)
        const container = document.getElementById("mmd");
        onWindowResize(container)
    }

    /**
     * create a Perspective camera
     * @param aspect aspect
     */
    function createCamera(aspect: number): THREE.PerspectiveCamera {
        return new THREE.PerspectiveCamera(50, aspect, 1, 2000)
    }

    /**
     * create a Scene
     */
    function createScene(): THREE.Scene {
        return new THREE.Scene()
    }

    /**
     * add light to scene
     * @param scene Three.Scene
     */
    function addLight(scene: THREE.Scene) {
        //add light
        const ambient = new THREE.AmbientLight(0x666666);
        scene.add(ambient);
        //add light
        const directionalLight = new THREE.DirectionalLight(0x887766);
        directionalLight.position.set(-1, 1, 1).normalize();
        scene.add(directionalLight);
    }

    /**
     * rerender when window resized
     * @param container the container
     */
    function onWindowResize(container: HTMLElement | null) {
        let width = window.innerWidth / 2;
        let height = window.innerHeight / 2;
        if (container != null) {
            width = container.clientWidth;
            height = container.clientHeight;
        }
        if (camera instanceof THREE.PerspectiveCamera) {
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        }
        renderer.setSize(width, height);
    }
    // called when the resource is loaded
    function onload(mesh: SkinnedMesh) {
        setMesh(mesh);
        if (scene != null) {
            scene.add(mesh);
            if (renderer != null && camera != null) {
                renderer.render(scene, camera);
                console.log("render finished");
            }
        }
    }

    // called when loading is in progresses
    function onprogress(xhr: ProgressEvent) {
        console.log((xhr.loaded / xhr.total * 100) + "% loaded");
    }

    // called when loading has errors
    function onerror(error: ErrorEvent) {
        setMesh(null);
        console.log("An error happened" + error);
    }

    /**
     * create a new Renderer
     */
    function createRenderer() {
        return new THREE.WebGLRenderer({antialias: true});
    }

    /**
     * init three.js
     */
    function init() {
        //set default width and height
        let width = window.innerWidth / 2;
        let height = window.innerHeight / 2;
        // scene.background = new THREE.Color(0x87939A);

        const container = document.getElementById("mmd");
        //set render dom to container and set width and height
        if (container) {
            container.appendChild(renderer.domElement);
            width = container.clientWidth;
            height = container.clientHeight;
        } else {
            console.log("mmd container not found");
        }
        //set renderer config
        if (renderer instanceof THREE.WebGLRenderer) {
            renderer.setPixelRatio(window.devicePixelRatio);
        }
        renderer.setSize(width, height);

        //create camera
        const cameraInstance = createCamera(width / height);
        setCamera(cameraInstance)
        //add light
        addLight(scene);
        //set mouse control
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.target.set(0, 10, 0);
        if (camera instanceof THREE.PerspectiveCamera) {
            window.addEventListener("resize", () => onWindowResize(container));
        }
        //update by mouse action
        animate()

        function animate() {
            requestAnimationFrame(animate);
            // required if controls.enableDamping or controls.autoRotate are set to true
            controls.update();
            renderer.render(scene, camera);
        }
    }

    useEffect(() => {
        init();
        console.log("init")
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        replaceModel(props.modelPath);
        // eslint-disable-next-line
    }, [props.modelPath])
    return (
        <div id="mmd" style={{width: "100%", height: "100%"}}/>
    );
}

export default MMD;
