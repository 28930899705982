import React, {useState} from "react";
import MMD from "components/MMD";
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Select, SelectChangeEvent
} from "@mui/material";
import MMDList from "assets/json/mmd.json"
import styles from "../../assets/css/genshin3d.module.scss";

function Genshin3DModel() {
    const [path, setPath] = useState<string>(MMDList[0].url);
    const [open, setOpen] = useState<boolean>(false)
    const [select, setSelect] = useState<string>(MMDList[0].url)
    function confirmChange() {
        setPath(select)
        setOpen(false)
    }
    function selectChanged(event: SelectChangeEvent,){
        setSelect(event.target.value)
    }
    function handleClose() {
        setOpen(false)
    }
    function handleOpen() {
        setOpen(true)
    }
    return (
        <div className={styles.three}>
            <div className={styles.mmd}>
                <MMD modelPath={path}/>
            </div>
            <div className={styles.bottom}>
                <Button variant="contained" onClick={handleOpen}>其它老婆</Button>
            </div>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>选老婆</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                native
                                value={select}
                                onChange={selectChanged}
                            >
                                {
                                    MMDList.map((item) => {
                                        return  <option value={item.url} key={item.name}>{item.name}</option>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={confirmChange}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Genshin3DModel;
