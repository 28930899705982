import React from "react";
import CardNavigation from "./navigation/CardNavigation";
import {Route, Routes} from "react-router-dom";
import Genshin3DModel from "./genshin/Genshin3DModel";
import Notes from "./notes/Notes"
function Main() {
    return (
        <Routes>
            <Route path="/" element={<CardNavigation />} />
            <Route path="/genshin3d" element={<Genshin3DModel />} />
            <Route path="/notes/*" element={<Notes/>} />
        </Routes>
    );
}

export default Main;
